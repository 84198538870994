// Custom css

require('../styles.scss');

// Fonts

import regularFont from '../fonts/angsa.ttf';
import boldFont from '../fonts/angsab.ttf';
import boldFont2 from '../fonts/MyriadProSemibold.ttf';

// Images

import favicon32 from '../favicon32x32.ico';
import favicon57 from '../favicon57x57.ico';
import favicon76 from '../favicon76x76.ico';
import favicon96 from '../favicon96x96.ico';
import favicon120 from '../favicon120x120.ico';
import favicon128 from '../favicon128x128.ico';
import favicon144 from '../favicon144x144.ico';
import favicon152 from '../favicon152x152.ico';
import favicon180 from '../favicon180x180.ico';
import favicon192 from '../favicon192x192.ico';
import favicon196 from '../favicon196x196.ico';
import favicon228 from '../favicon228x228.ico';

import backgroundHome from '../img/backgrounds/home.jpg';
import backgroundAbout from '../img/backgrounds/about.jpg';
import backgroundRequirements from '../img/backgrounds/requirements.jpg';
import backgroundApp from '../img/backgrounds/application.jpg';
import backgroundCalculator from '../img/backgrounds/calculator.jpg';
import backgroundContact from '../img/backgrounds/contact.jpg';

import partner1 from '../img/partners/bangkok-bank.svg';
import partner2 from '../img/partners/bank-of-ayudhya.svg';
import partner3 from '../img/partners/kasikorn-bank.svg';
import partner4 from '../img/partners/krungthai-bank.svg';
import partner5 from '../img/partners/siam-commercial-bank.svg';
import partner6 from '../img/partners/thailand-ministry-of-finance.svg';
import partner7 from '../img/partners/thanachart-bank.svg';
import partner8 from '../img/partners/tmb-bank.svg';

import arrow from '../img/arrow.jpg';
import check from '../img/check.svg';
import facebook from '../img/facebook.svg';
import logo from '../img/logo.svg';

// Custom script

var $ = require("jquery");

import scripts from '../js/scripts';
import spinner from 'spin.js';
window.spinner = spinner;
import 'bootstrap/js/src/button';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/util';
// import 'bootstrap/js/src/index';


